import { createRouter,  createWebHistory } from 'vue-router'
import PcCom from '../views/PcCom.vue'
import H5Com from '../views/H5Com.vue'
const routes = [
  {
    path: '/',
    component: PcCom,
    redirect: '/',
    children:[
      {
          path: "/",
          name: "H5Index",
          component: () => import('@/views/H5/index.vue'),
          meta: { title: "首页", icon: "page"},
      },
    ]
  },
  {
    path: '/h5',
    name: "H5Com",
    component: H5Com,
    redirect: '/h5',
    children:[
      {
        path: "/h5/index",
        name: "H5",
        component: () => import('@/views/H5/index.vue'),
        meta: { title: "首页", icon: "page"},
      },
      {
        path: "/h5/promotion",
        name: "Promotion",
        component: () => import('@/views/H5/promotion.vue'),
        meta: { title: "晋升", icon: "page"},
      },
      {
        path: "/h5/my",
        name: "My",
        component: () => import('@/views/H5/my.vue'),
        meta: { title: "我的", icon: "page"},
      },
      {
        path: "/h5/team",
        name: "Team",
        component: () => import('@/views/H5/team.vue'),
        meta: { title: "团队", icon: "page"},
      },
    ]
  },
  {
    path: "/h5/faq",
    name: "FAQ",
    component: () => import('@/views/H5/faq.vue'),
    meta: { title: "常见问题", icon: "page"},
  },
  {
    path: "/h5/gameList",
    name: "GameList",
    component: () => import('@/views/H5/gameList.vue'),
    meta: { title: "游戏列表", icon: "page"},
  },
  {
    path: "/h5/share",
    name: "Share",
    component: () => import('@/views/H5/share.vue'),
    meta: { title: "分享", icon: "page"},
  },
  {
    path: "/h5/gameDetail",
    name: "GameDetail",
    component: () => import('@/views/H5/gameDetail.vue'),
    meta: { title: "游戏详情", icon: "page"}
  },
  {
    path: "/h5/promotionDetail/:id",
    name: "PromotionDetail",
    component: () => import('@/views/H5/promotionDetail.vue'),
    meta: { title: "晋升详情", icon: "page"},
  },
  {
    path: "/h5/topUp",
    name: "TopUp",
    component: () => import('@/views/H5/topUp.vue'),
    meta: { title: "充值", icon: "page"},
  },
  {
    path: "/h5/withdraw",
    name: "Withdraw",
    component: () => import('@/views/H5/withdraw.vue'),
    meta: { title: "提现", icon: "page"},
  },
  {
    path: "/h5/moneyUrl",
    name: "MoneyUrl",
    component: () => import('@/views/H5/moneyUrl.vue'),
    meta: { title: "跳转链接", icon: "page"},
  },
  {
    path: "/h5/order",
    name: "Order",
    component: () => import('@/views/H5/order.vue'),
    meta: { title: "游戏记录", icon: "page"},
  },
  {
    path: "/h5/game",
    name: "Game",
    component: () => import('@/views/H5/game.vue'),
    meta: { title: "游戏", icon: "page"},
  },
  {
    path: "/h5/wallets",
    name: "Wallets",
    component: () => import('@/views/H5/wallets.vue'),
    meta: { title: "钱包", icon: "page"},
  },
  {
    path: "/h5/funds",
    name: "Funds",
    component: () => import('@/views/H5/funds.vue'),
    meta: { title: "账户记录", icon: "page"},
  },
  {
    path: "/h5/opinion",
    name: "Opinion",
    component: () => import('@/views/H5/opinion.vue'),
    meta: { title: "提议", icon: "page"},
  },
//     h5/opinion
]

const router = createRouter({
  // history: createWebHashHistory(),
  // mode: 'history',
  history: createWebHistory("/"),
  routes
})

export default router
