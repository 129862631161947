import API from '@/utils/request';
import store from '../store';
import {showToast} from "vant";
import clipboard3 from 'vue-clipboard3';
const { toClipboard } = clipboard3();
// import { useI18n } from 'vue-i18n'
// console.log(useI18n(), '**');


/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 *  获取今天、昨天、明天的日期
 * dates为数字类型，0代表今日,-1代表昨日，1代表明日，返回yyyy-mm-dd格式字符串，dates不传默认代表
 * 
 */
export function getDate(dates) {
    var dd = new Date();
    var n = dates || 0;
    dd.setDate(dd.getDate() + n);
    var y = dd.getFullYear();
    var m = dd.getMonth() + 1;
    var d = dd.getDate();
    m = m < 10 ? "0" + m: m;
    d = d < 10 ? "0" + d: d;
    var day = y + "-" + m + "-" + d;
    return day;
}

/**
 * 获取本周、上周、下周的起始日期
 * type为字符串类型，有两种选择，"s"代表开始,"e"代表结束，dates为数字类型，不传或0代表本周，-1代表上周，1代表下周
 * @param {*} type 
 * @param {*} dates 
 * @returns 
 */
export function getMonday(type, dates) {
    var now = new Date();
    var nowTime = now.getTime();
    var day = now.getDay();
    var longTime = 24 * 60 * 60 * 1000;
    var n = longTime * 7 * (dates || 0);
    var dd;
    if (type == "s") {
        dd = nowTime - (day - 1) * longTime + n;
    }
    if (type == "e") {
        dd = nowTime + (7 - day) * longTime + n;
    }
    dd = new Date(dd);
    var y = dd.getFullYear();
    var m = dd.getMonth() + 1;
    var d = dd.getDate();
    m = m < 10 ? "0" + m: m;
    d = d < 10 ? "0" + d: d;
    var dayNew = y + "-" + m + "-" + d;
    return dayNew;
}

/**
 * 邮箱验证
 * 
 * str 邮箱链接
 */
export function isEmail(str) {
    let reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
    return reg.test(str);
}

/**
 * 电话号码验证（判断长度）
 * 
 */
export function isPhoneNumber(str) {
    if(str.length == 10 || str.length == 11) {
        return true;
    } else {
        return false;
    }
}

/**
 * 是否是在移动端
 * 
 */
export function isMobile() {
    const isMobile = /iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone/i.test(navigator.userAgent);
    return isMobile;
}

// 检测API时效性
export function checkApiTime (callback) {
    API.getRequest("api/user/info",{},() => {},true, callback)
}

// 登录信息存储
export function loginIn(_token, _userInfo) {
    if(_token) {
        localStorage.setItem("AutoToken", _token);
        store.commit('setAutoToken', _token);
    }
    if(JSON.stringify(_userInfo) !== '{}') {
        localStorage.setItem('UserInfo',JSON.stringify(_userInfo));
        store.commit('setUserInfo', _userInfo);
    }
}

// 退出登录处理
export function loginOut() {
    localStorage.removeItem("UserInfo");
    localStorage.removeItem("AutoToken");
    store.commit("setAutoToken", '');
    store.commit("setUserInfo", {});
}

//  请求登录
export function requestLoginMethods(callback) {
    if(store.state.autoToken) {
        typeof callback === 'function' && callback();
    } else {
        showToast("Por favor, inscreva-se");
        setTimeout(() => {
            store.commit('setLoginWindow', 1);
            store.commit("setLoginWindowJudge", store.state.loginWindowJudge + 1);
        },2000);
    }
}

//复制
export function copyContent (content) {
    try {
        toClipboard(content);
        showToast("Copiado com sucesso");
    } catch (error) {
        showToast(error);
        console.log('error')
    }
}

// 客服跳转
export function customerJump () {
    window.open(store.state.commonConfigInfo.customerService, '_blank')
}