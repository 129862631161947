export default{
    components: {
        f1: '首页',
        f2: '促销',
        f3: '代理',
        f4: '订单',
        f5: '我的',
        f6: '登录',
        f7: '空数据',
        b1: "确定",
        b2: "取消",
    },
    index: {
        i1: '存款',
        i2: '撤回',
        i3: '登录',
        i4: '注册',
        i5: '欢迎来到 BBJJ.COM，最好的游戏之家！作为PG SLOTS全球领先的合作伙伴',
        i6: '更多',
        t1: '最近',
        t2: '投注记录',
        t3: '促销',
        t4: '轮盘赌',
        t5: '贵宾',
        t6: '胸部',
        t7: '资金',
        t8: '奖品',
        t9: '代理',
        t10: '葡萄牙语',
        t11: '下载应用程序',
        t12: '联系支持',
        t13: '常见问题解答',
    },
    login: {
        l1: '登录',
        l2: '输入用户名',
        l3: '输入密码',
        l4: '自动登录',
        l5: '忘记密码',
        l6: '注册',
        l7: '电话',
        l8: '通信',
        l9: '在线服务',
        l10: '电话',
        l11: '通信',
        l12: '手机号码',
        l13: '您的电子邮件地址',
        l14: '您的邀请码',
        l15: '输入密码',
        l16: '请确认您的密码',
        l17: '我确认我已年满 18 周岁并已阅读服务条款',
        l18: '实验游戏',
    },
    my: {
        m1: '支持',
        m2: '消息',
        m3: '数据',
        m4: '账户',
        m5: '恢复平衡',
        m6: '提取',
        m7: '存款',
        m8: '费用',
        m9: '充值雷亚尔',
        m10: '晋级投注',
        m11: '恢复平衡',
        m12: '晋升',
        m13: '投注记录',
        m14: '报告',
        m15: '提币管理',
        m16: '邀请',
        m17: '数据',
        m18: '安全中心',
        m19: '音乐',
        m20: '常见问题解答',
        m21: '观点',
        m22: '退出',
        w1: '恢复平衡',
        w2: '雷亚尔',
        w3: '当前主钱包',
        w4: '我的货币',
        w5: '卡片',
    },
    team: {
        t1: '邀请',
        t2: '推广教程',
        t3: '邀请链接',
        t4: '佣金',
        t5: '表现',
        t6: '所有数据',
        t7: '下属财务状况',
        t8: '下属投注',
        t9: '从属投注',
        t10: '下属统计',
        t11: '下属要求',
        t12: '佣金费',
        s1: '过去',
        s2: '天发放的奖金',
        s3: '邀请奖金',
        s4: '投注佣金',
        s5: '收到来自',
        s6: '人',
        s7: '代理规则说明',
        s71: '邀请用户成为您团队的成员，您将因其有效投注而获得折扣奖励',
        s72: '有效投注总额=所有比赛的有效投注总额',
        s73: '每场比赛的有效赌注=您在单场比赛中的输赢金额',
        s74: '1级：您将获得1级会员有效投注0.2%的折扣奖金',
        s75: '2级：您将获得2级会员有效投注0.1%的折扣奖金',
        s76: '示例：1名参与者每天下注1000R$，R$1R$的奖励100名订阅者在1天内赚取100R$。',
        s77: '3级：您将获得3级会员有效投注0.05%的折扣奖金',
        s78: '示例：1名参与者每天下注1000R$，奖励0.5R$100名订阅者1天可赚取50R$。',
        s8: '奖励规则说明',
        s81: '对于您邀请的每个首次存款用户，您将获得15R美元的奖金',
        s82: '满',
        s83: '人可以奖励',
        s84: 'R$',
        s9: '促销说明',
        s91: '1.什么是下属？',
        s92: '在分享页面上，将你的分享链接分享给你的朋友，让你的朋友成为你的下属，你的朋友提拔的人也可以成为你的下级，提拔更多的用户会给你带来更多的佣金。',
        s93: '2.如何检查下属给你带来了多少',
        s94: '在分享页面上，将你的分享链接分享给你的朋友，让你的朋友成为你的下属，你的朋友提拔的人也可以成为你的下级，提拔更多的用户会给你带来更多的佣金。',
        s11: '我的身份',
        s12:'历史',
        s13: '接收',
        s14: '促销信息',
        s15: '点击保存',
        s16: '独家链接',
        s17: '佣金数据',
        s18: '转换数据',
        s171: '团队',
        s172: '团队总人数',
        s173: '今日新添人数',
        s174: '昨日新添人数',
        s175: '层级',
        s176: '人数',
        s181: '有效投注',
        s182: '充值',
        s183: '返拥',
        s184: '首充人数',
    },
    game: {
        g1: '订单',
        g2: '游戏历史',
        g3: '投注单总数',
        g4: '总收入',
        g5: '总费用',
        g6: '今天',
        g7: '昨天',
        g8: '本周',
        g9: '投注金额',
        z1: '轮盘赌',
        z2: '抽奖次数',
        z3: '命运之轮',
        z4: '总计',
        z5: '获奖通知',
        z6: '获奖记录',
        z7: '抽奖',
        z8: '轮盘赌',
    },
    opinion: {
        o1: '意见',
        o2: '意见类型',
        o3: '活动建议',
        o4: '请输入标题',
        o5: '请输入内容',
        o6: '发送',
        o7: '活动建议',
        o8: '功能建议',
        o9: '游戏建议',
        o10: '其他问题',
        o11: '提交成功',
    },
    funds: {
        f1: '资金',
        f2: '账户变更记录',
        f3: '一切',
        f4: '全部',
        w1: '充值',
        w2: '提现',
        w3: '真金投注',
        w4: '赏金投注',
        w5: '中奖真金',
        w6: '游戏平台调增金额',
        w7: '游戏平台调减金额',
        w8: '中奖赏金',
        w9: '活动赏金',
        w10: '薪金发放',
        w11: '个人投注返佣',
        w12: '下级投注返佣',
        w13: '邀请首次充值返佣',
        w14: '累计邀请人数返佣',
        w15: '手续费',
        w16: '人工调增真金',
        w17: '人工调减真金',
        w18: '人工调增赏金',
        w19: '人工调减赏金',
        w20: '人工调增提现余额',
        w21: '人工调减提现余额',
        w22: '退款',
        w23: '游戏平台赠送',
        w24: '取消投注',
        w25: '优惠券',
        w26: '排行榜'
    },

}