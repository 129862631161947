<script setup>
import {reactive, defineProps, defineEmits, onMounted} from 'vue';
import { showToast } from 'vant';
import {loginPost,userWalletGet,registerPost,levelListGet} from '@/utils/api';
import {loginIn, isPhoneNumber, isEmail} from "@/utils/validate";
import { useStore } from "vuex";
// import { useI18n } from 'vue-i18n'

// const { t } = useI18n()
const store = useStore();
const props = defineProps({
  tkShow: Boolean,
});
const emit = defineEmits(['close']);

const loginRegisterData = reactive({
  show: props.tkShow,
  loginInfo: {
    username: '',
    password: '',
    automatic: false,
  },
  type: store.state.loginWindow,
  telAndMail: 1,  // 电话  1  邮箱  2
  deviceInfo: {
    ua: ''
  },
  registerInfo: {
    truePassword: "",
    password: "",
    shareCode: "",
    username: "",
    automatic: false,
  },
  truePasswordEye: false,
  passwordEye: false
})

// 关闭弹窗
const closeTk = () => {
  emit('close')
}

// 默认缓存登录信息
const loginAutomatic = () => {
  if(loginRegisterData.loginInfo.automatic) {
    let obj = {
      username: loginRegisterData.loginInfo.username,
      password: loginRegisterData.loginInfo.password,
    }
    localStorage.setItem("loginInfo", JSON.stringify(obj));
  } else {
    localStorage.removeItem("loginInfo");
  }
}

// 登录
const loginBtn = () => {
  let _loginInfo = loginRegisterData.loginInfo;
  if(!_loginInfo.username) {
    showToast("Inserir nome de usuário")
    return;
  }
  if(!_loginInfo.password) {
    showToast("Introduza a palavra-passe")
    return;
  }
  loginPost({
    "deviceInfo": JSON.stringify(loginRegisterData.deviceInfo),
    "password": _loginInfo.password,
    "username": _loginInfo.username,
  }, (res) => {
    loginAutomatic();
    showToast(res.msg);
    if(res.code == 0) {
      loginIn(res.data.token, {});
      userWalletGet((val) => {
        let obj =  Object.assign(res.data.user, val.data);
        loginIn('', obj);
        closeTk();
        levelListGetFun();
      })
    }
  })
}

// 注册
const registerBtn = () => {
  const _registerInfo = loginRegisterData.registerInfo;
  if(!_registerInfo.automatic) {
    showToast("Por favor, verifique o contrato de serviço");
    return;
  }
  if(!_registerInfo.username) {
    showToast("Por favor insira seu e-mail ou número de celular");
    return;
  }
  if(!_registerInfo.password) {
    showToast("Por favor insira a senha");
    return;
  }
  if(!_registerInfo.truePassword) {
    showToast("Por favor confirme sua senha");
    return;
  }
  if(_registerInfo.password != _registerInfo.truePassword) {
    showToast("As duas senhas são inconsistentes");
    return;
  }
  if(loginRegisterData.telAndMail == '1' && !isPhoneNumber(_registerInfo.username)) {
    showToast("o número de telefone está incorreto");
    return;
  }
  if(loginRegisterData.telAndMail == '2' && !isEmail(_registerInfo.username)) {
    showToast("O e-mail está incorreto");
    return;
  }
  registerPost({
    "deviceInfo": JSON.stringify(loginRegisterData.deviceInfo),
    "password": _registerInfo.password,
    "shareCode": _registerInfo.shareCode,
    "username": _registerInfo.username
  },(res) => {
    if(res.code == '0') {
      loginIn(res.data.token, {});
      userWalletGet((val) => {
        let obj =  Object.assign(res.data.user, val.data)
        loginIn('', obj);
        closeTk();
        levelListGetFun();
      })
    }
  })
};

// VIP等级
const levelListGetFun = () => {
  levelListGet((res) => {
    localStorage.setItem("vipList", JSON.stringify(res.data));
  })
}
// 客服跳转
const customerJump = () => {
  window.open(store.state.commonConfigInfo.customerService, '_blank')
};


onMounted(() => {
  loginRegisterData.deviceInfo.ua = navigator.userAgent;
  if(localStorage.getItem("loginInfo")) {
    let userInfo = JSON.parse(localStorage.getItem("loginInfo"));
    loginRegisterData.loginInfo.username = userInfo.username;
    loginRegisterData.loginInfo.password = userInfo.password;
    loginRegisterData.loginInfo.automatic = true;
  }
  // 分享码
  if(store.state.shareCode || localStorage.getItem('shareCode')) {
    loginRegisterData.registerInfo.shareCode = store.state.shareCode?store.state.shareCode : localStorage.getItem('shareCode');
  }
})

</script>

<template>
<div class="lr-main">
  <div class="mask" v-if="loginRegisterData.show" @click="closeTk"></div>
  <transition name="bounce"   enter-active-class="animate__animated animate__fadeInLeft"
              leave-active-class="animate__animated animate__fadeOutLeft">
    <div class="lr-m-body" v-if="loginRegisterData.show">
      <div class="lr-mb-content">
        <div class="block" v-if="loginRegisterData.type == 1">
          <div class="title">{{$t('login.l1')}}</div>
          <div class="sr">
            <div class="divFlex">
              <img class="demo" src="@/assets/images/component/l1.svg" alt="">
              <input v-model="loginRegisterData.loginInfo.username" type="text" :placeholder="$t('login.l2')">
              <img v-if="loginRegisterData.loginInfo.username" class="close" src="@/assets/images/component/close2.svg" @click="loginRegisterData.loginInfo.username = ''" alt="">
            </div>
            <div class="divFlex">
              <img class="demo" src="@/assets/images/component/l2.svg" alt="">
              <input v-model="loginRegisterData.loginInfo.password" type="password" :placeholder="$t('login.l3')">
              <img v-if="loginRegisterData.loginInfo.password" class="close" src="@/assets/images/component/close2.svg" @click="loginRegisterData.loginInfo.password = ''" alt="">
            </div>
          </div>
          <div class="desc divFlex">
            <div @click="loginRegisterData.loginInfo.automatic = !loginRegisterData.loginInfo.automatic">
              <div class="middle choose" :style="loginRegisterData.loginInfo.automatic?'border: 0':''">
                <img v-if="loginRegisterData.loginInfo.automatic" src="@/assets/images/component/choose.svg" alt="">
              </div>
              <i class="middle">{{$t('login.l4')}}</i>
            </div>
            <span>{{$t('login.l5')}}？</span>
          </div>
          <div class="btn">
            <div @click="loginBtn">{{$t('login.l1')}}</div>
          </div>
        </div>
        <div class="block"  v-if="loginRegisterData.type == 2">
          <div class="title">{{$t('login.l6')}}</div>
          <div class="choose">
            <div>
              <span :class="loginRegisterData.telAndMail == '1'?'active':''" @click="loginRegisterData.telAndMail = 1;loginRegisterData.registerInfo.username = ''">{{$t('login.l10')}}</span>
              <span :class="loginRegisterData.telAndMail == '2'?'active':''" @click="loginRegisterData.telAndMail = 2;loginRegisterData.registerInfo.username = ''">{{$t('login.l11')}}</span>
            </div>
          </div>
          <div class="sr">
            <div class="divFlex phone" v-if="loginRegisterData.telAndMail == '1'">
              <div>
                <img class="middle" style="height: .48rem;" src="@/assets/images/component/l3.svg" alt="">
                <img class="middle" style="height: .4rem;margin: 0 .12rem 0 .08rem;" src="@/assets/images/component/l4.png" alt="">
                <span class="middle">+55</span>
                <img class="middle" style="height: .24rem;margin-left: .18rem;" src="@/assets/images/component/l5.svg" alt="">
              </div>
              <input v-model="loginRegisterData.registerInfo.username" style="width: 56%" type="number" :placeholder="$t('login.l12')" />
            </div>
            <div class="divFlex" v-if="loginRegisterData.telAndMail == '2'">
              <img class="demo" src="@/assets/images/component/l9.svg" alt="" style="height: .42rem;">
              <input v-model="loginRegisterData.registerInfo.username" type="text" :placeholder="$t('login.l13')">
              <img v-if="loginRegisterData.registerInfo.username" @click="loginRegisterData.registerInfo.username = ''" class="close" src="@/assets/images/component/close2.svg" alt="">
            </div>
            <div class="divFlex">
              <img class="demo" src="@/assets/images/component/l10.svg" alt="" style="height: .42rem;">
              <input v-model="loginRegisterData.registerInfo.shareCode" type="text" :placeholder="$t('login.l14')">
              <img v-if="loginRegisterData.registerInfo.shareCode" @click="loginRegisterData.registerInfo.shareCode = ''" class="close" src="@/assets/images/component/close2.svg" alt="">
            </div>
            <div class="divFlex">
              <img class="demo" src="@/assets/images/component/l2.svg" alt="">
              <input v-model="loginRegisterData.registerInfo.password" :type="loginRegisterData.passwordEye?'text':'password'" :placeholder="$t('login.l15')">
              <img v-if="loginRegisterData.passwordEye" class="close" src="@/assets/images/component/l6.svg" alt="" @click="loginRegisterData.passwordEye = !loginRegisterData.passwordEye">
              <img v-else class="close" src="@/assets/images/component/l7.svg" alt="" @click="loginRegisterData.passwordEye = !loginRegisterData.passwordEye">
            </div>
            <div class="divFlex">
              <img class="demo" src="@/assets/images/component/l2.svg" alt="">
              <input v-model="loginRegisterData.registerInfo.truePassword" :type="loginRegisterData.truePasswordEye?'text':'password'" :placeholder="$t('login.l16')">
              <img v-if="loginRegisterData.truePasswordEye" class="close" src="@/assets/images/component/l6.svg" alt="" @click="loginRegisterData.truePasswordEye = !loginRegisterData.truePasswordEye">
              <img v-else class="close" src="@/assets/images/component/l7.svg" alt="" @click="loginRegisterData.truePasswordEye = !loginRegisterData.truePasswordEye">
            </div>
          </div>
          <div class="desc divFlex" style="align-items: flex-start;"  @click="loginRegisterData.registerInfo.automatic = !loginRegisterData.registerInfo.automatic">
            <div>
              <div class="middle choose" :style="loginRegisterData.registerInfo.automatic?'border: 0':''">
                <img v-if="loginRegisterData.registerInfo.automatic" src="@/assets/images/component/choose.svg" alt="">
              </div>
            </div>
            <span style="width: calc(100% - .45rem);color: #5B5C5F;">{{$t('login.l17')}}</span>
          </div>
          <div class="btn">
            <div @click="registerBtn">{{$t('login.l6')}}</div>
          </div>
        </div>
        <div class="desc divFlex">
          <span @click="closeTk">{{$t('login.l18')}}</span>
          <span @click="loginRegisterData.type = loginRegisterData.type === 1?2:1;">{{loginRegisterData.type == 2?$t('login.l1'):$t('login.l6')}}</span>
        </div>
        <div class="desc divFlex" style="justify-content: center" @click="customerJump">
          <span>{{$t('login.l9')}}</span>
        </div>
      </div>
      <img  @click="closeTk" src="@/assets/images/component/close.svg" alt="">
    </div>
    </transition>
</div>
</template>

<style scoped lang="scss">
.mask{
  z-index: 1002;
}
.lr-main{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1002;
  .lr-m-body{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    width: 100%;
    z-index: 1002;
    >.lr-mb-content{
      width: calc(100% - 1rem);
      background-color: #fff;
      border-radius: .12rem;
      padding: .26rem .32rem .36rem;
      margin: 0 auto;
      .title{
        font-size: .30rem;
        text-align: center;
        color: #000;
      }
      .choose{
        >div{
          width: 90%;
          margin: 0 auto;
          border: .01rem solid #dedede;
          text-align: center;
          border-radius: .64rem;
          overflow: hidden;
          span{
            display: inline-block;
            width: 50%;
            line-height: .64rem;
            &.active{
              color: $font-color1;
              background-color: $font-color4;
            }
          }
        }
      }
      .sr{
        >div{
          padding: 0 .24rem 0 .72rem;
          height: .68rem;
          border-radius: .12rem;
          border: .01rem solid #dedede;
          margin-bottom: .32rem;
          position: relative;
          &:last-of-type{
            margin-bottom:  0;
          }
          &.phone{
            padding: 0 .24rem;
            >div{
              width: 44%;
              border-right: .01rem solid #dedede;
            }
            >input{
              text-align: right;
            }
          }
        }
        img{
          &.demo{
            position: absolute;
            left: .24rem;
            top: 50%;
            transform: translate(0, -50%);
            height: .48rem;
          }
          &.close{
            width: .32rem;
            height: .32rem;
          }
        }
        input{
          width: calc(100% - .32rem);
          padding: 0 .1rem;
          line-height: .68rem;
        }
      }
      .desc{
        color: $font-color4;
        .choose{
          width: .32rem;
          height: .32rem;
          border-radius: .12rem;
          border: .01rem solid #dedede;
          img{
            width: 100%;
          }
        }
        i{
          margin-left: .16rem;
          font-style: normal;
          color: #5B5C5F;
        }
      }
      .btn{
        >div{
          height: .68rem;
          line-height: .68rem;
          text-align: center;
          border-radius: .12rem;
          background-color: $font-color4;
          color: $font-color1;
        }
      }
      >div{
        margin-bottom: .4rem;
        &:last-of-type{
          margin-bottom: 0;
        }
      }
      .block{
        >div{
          margin-bottom: .4rem;
          &:last-of-type{
            margin-bottom: 0;
          }
        }
      }
    }
    >img{
      width: .56rem;
      height: .56rem;
      display: block;
      margin: .32rem auto 0;
    }
  }
}
</style>